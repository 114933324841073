
import { Button, Menu, MenuItem } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import i18n from '../../services/i18n'
import { updateLanguage } from "../../Store/salesforceSlice"

const ButtonLanguage = () => {

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const currentLanguage = useSelector(state => state.salesforce.language)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    const { language } = event.currentTarget.dataset
    if (language !== undefined) {
      dispatch(updateLanguage(language))
      i18n.changeLanguage(language)
    }
    setAnchorEl(null)
  }

  useEffect(() => {
    dispatch(updateLanguage(i18n.language))
    // if (i18n.language !== 'fr' && i18n.language !== 'en') {
    //   dispatch(updateLanguage('fr'))
    //   i18n.changeLanguage('fr')
    // }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        // sx={{ mr: 2 }}
      >
        {currentLanguage}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem data-language="en" disabled={currentLanguage === 'en'} onClick={handleClose}>EN</MenuItem>
        <MenuItem data-language="fr" disabled={currentLanguage === 'fr'} onClick={handleClose}>FR</MenuItem>
      </Menu>
    </>
  )
}

export default ButtonLanguage
